<template lang="pug">
  v-container
    v-toolbar(
      color="primary"
      dark
    )
      v-toolbar-title Suppliers
      v-spacer
      v-icon mdi-account-group
    v-data-table(
      :items="splrList"
      :headers="headers"
    )
</template>
<script>
import splrRepository from '@/repositories/supplier.repository'
import { getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const getSplrDelay = searchDelay()

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Category', value: 'supplier_category_id' },
    { text: 'Company Name', value: 'company_name' },
    { text: '', value: 'data-table-expanded' },
  ],
})

export default {
  name: 'SupplierTable',
  created () {
    this.getSplr()
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('splr'),
    }
  },
  methods: {
    getSplr () {
      if (this.splrGetting) return
      this.splrGetting = false
      this.splrGetErrors = []
      getSplrDelay(() => {
        splrRepository.index()
          .then(({ data }) => { this.splrList = data })
          .catch(e => { this.splrGetErrors = errorHandler(e) })
          .then(() => { this.splrGetting = false })
      })
    },
  },
}
</script>
